<template>
  <AppMainContainer
    v-if="offer"
    center-class="offer-item"
    :show-modal="!!currentModal"
    @isScroll="(val) => {isScroll = val}"
    @closeModal="closeModal"
  >
    <template #subheader>
      <OfferItemHeader
        id="offer-header-container"
        :style="offer.deletedAt ? {'opacity': 0.6}: {}"
        :offer="offer"
        :selectedUser="selectedUser"
        @cancelOffer="onCancel"
        @reloadOffer="reload"
      />
      <div
        style="
      display: flex; flex-direction: row; justify-content: flex-end; align-items: center;margin: 8px"
      >
        <div
          v-if="!offer.deletedAt && !isEditMode"
          class="control-wrapper"
          @click="toEditMode"
        >
          <IconPanEdit />
          <span style="display: inline-block; margin-left: 6px; margin-right: 8px">Управление</span>
        </div>
        <div
          v-if="isEditMode"
          class="control-wrapper"
          @click="toViewMode"
        >
          <span
            class="control-wrapper"
            style="
            display: inline-block; margin-left: 6px; margin-right: 8px; color: #8BD118;
            border: 1px solid #8BD118; border-radius: 8px; padding: 2px 8px"
          >Передумал</span>
        </div>
        <div
          v-if="isEditMode && getMaxCancelableOffers > 0"
          :class="{
            'control-disabled': !getMaxCancelableOffers,
            'control-wrapper': !!getMaxCancelableOffers,
          }"
          @click="openLaborerCancelModal"
        >
          <span
            class="control-wrapper cancel-btn-txt"
            style="display: inline-block; margin-left: 6px; margin-right: 8px"
          >Отменить офферы</span>
        </div>
      </div>
      <div
        v-if="offer.dayNumber === 1"
        style="font-size: 1.2em; color: red;text-align: center;padding: 16px"
      >
        {{ offer.publicationMessage }}
      </div>
    </template>

    <template #center>
      <app-table
        style="width: 100%;"
        :style="offer.deletedAt ? {'opacity': 0.6}: {}"
        :keys="keys"
        :array="getWorkDayList"
        :isEdit="isEditMode"
        :checked-item="selectedWorkDayIdList"
        :checked-call-back="comboChecker"
        @selectCombobox="onRowSelected"
        @click="(item) => gotoView(item)"
        @selectAll="(val) => {onSelectAll(val)}"
      >
        <template #laborer="{item: workDay}">
          <span
            style="display: inline-block; position: relative"
            class="vacancies__table__employee"
          >
            <span
              style="position: absolute; top: -9px; right: 2px;font-weight: bold; color: orangered"
            >{{ `${workDay.requiredLaborer ? '+' : ''}${workDay.requiredLaborer}` }}</span>
            <IconEmployee
              :fill="getLaborerColor(workDay)"
              style="vertical-align: middle;"
            />
          </span>
        </template>
        <template #vacancy.title="{item: workDay}">
          <span
            class="vacancies__table__employee"
          >
            {{ offer.vacancy.title }}
          </span>
        </template>
        <template #startDate="{item: workDay}">
          <span
            class="vacancies__table__employee"
          >
            {{ `${workDay.startDate} ${workDay.startTime}` }}
          </span>
        </template>
        <template #endDate="{item: workDay}">
          <span
            class="vacancies__table__employee"
          >
            {{ `${workDay.endDate} ${workDay.endTime}` }}
          </span>
        </template>
        <template #duration="{item: workDay}">
          <span
            class="vacancies__table__employee"
          >
            {{ offer.duration }}
          </span>
        </template>
        <template #reservedAmount="{item: workDay}">
          <span
            class="vacancies__table__employee"
          >
            {{ offer.reservedAmount }}
          </span>
        </template>
        <template #offerStatus="{item: workDay}">
          <span
            class="vacancies__table__employee"
          >
            {{ workDay.statusLabel }}
          </span>
        </template>
      </app-table>
    </template>

    <template #modal>
      <AppModalSmall
        v-if="currentModal === workDayCancelModal"
        style="padding: 32px 26px; max-width: 420px"
      >
        <h1 class="modal__header">
          {{ `Вы уверены, что хотите отменить ${selectedRowList.length} ${pluriaizeOffers(selectedRowList.length)}` }}
        </h1>
        <p style="text-align: center; font-size: 0.9em; color: rgba(0, 0, 0, 0.51); margin-top: 16px">
          {{ `Укажите количество соискателей в каждом оффере` }}
        </p>

        <div
          style="display: flex; justify-content: center; align-items: center"
          class="modal__button"
        >
          <IconMinus
            style="cursor: pointer"
            :fill="cancelledLaborerNumber > 1 ? '#8BD118' : '#0000004d'"
            @click="onCancelNumberChange(false)"
          />
          <span style="display: inline-block; padding-left: 5px; padding-right: 5px">
            {{ ` ${cancelledLaborerNumber} ${pluriaizeLaborer(cancelledLaborerNumber)}` }} </span>
          <IconPlusSmall
            style="cursor: pointer"
            :fill="cancelledLaborerNumber === getMaxCancelableOffers ? '#0000004d' : '#8BD118'"
            @click="onCancelNumberChange(true)"
          />
        </div>

        <div class="cancel-description">
          <span style="font-weight: 900; font-size: 1.1em; ">
            {{ `Не более ${getMaxCancelableOffers} ${pluriaizeLaborer(getMaxCancelableOffers)}` }}
          </span>
        </div>

        <div
          class="modal__button"
        >
          <AppLoader v-if="isLoading" />
          <AppButton
            v-else
            class="button__warm-action"
            @click.native="onWorkDayCancel"
          >
            Отменить офферы
          </AppButton>
        </div>

        <a
          v-if="!isLoading"
          class="modal__button-cancel"
          @click.prevent="closeLaborerCancelModal"
        >Отмена</a>
      </AppModalSmall>
      <AppModalSmall
        v-if="currentModal === futureViewAlertModal"
        style="padding: 16px; width: 380px"
      >
        <p style="text-align: center; color: rgba(0, 0, 0, 0.9); margin-top: 16px">
          {{ alertModalDescription }}
        </p>

        <div
          class="modal__button"
        >
          <AppButton
            @click.native="closeModal"
          >
            OK
          </AppButton>
        </div>
      </AppModalSmall>
    </template>
  </AppMainContainer>
</template>

<script>
import AppMainContainer from '@/components/common/layout/AppMainContainer'
import OfferItemHeader from '@/components/offer/OfferItemHeader'
// import OfferItemUserStatus from '@/components/offer/OfferItemUserStatus'
// import OfferItemChatUserCard from '@/components/offer/OfferItemChatUserCard'
// import OfferItemChat from '@/components/offer/OfferItemChat'
import AppModalSmall from '@/components/common/modal/AppModalSmall'
import AppButton from '@/components/common/simple/AppButton'
import AppLoader from '@/components/AppLoader'
import IconPlusSmall from '@/components/common/icons/IconPlusSmall'
import IconMinus from '@/components/common/icons/IconMinus'
import AppTable from '@/components/common/AppTable'
import IconEmployee from '@/components/common/icons/IconEmployee'
import { routeList } from '@/router/office'
import officeApi from '@/mixins/office/officeApi'
import IconPanEdit from '@/components/common/icons/IconPanEdit'
import { pluriaizeOffers, pluriaizeLaborer } from '@/utils/plural-util'
import { mapOfferList, mapOffer } from '@/utils/offerUtil'

const modalName = {
}

export default {
  name: 'PageOfferItem',
  components: {
    AppTable,
    IconPanEdit,
    IconEmployee,
    AppButton,
    AppLoader,
    IconPlusSmall,
    IconMinus,
    AppModalSmall,
    // OfferItemChat,
    // OfferItemChatUserCard, OfferItemUserStatus,
    OfferItemHeader, AppMainContainer
  },
  mixins: [officeApi],
  props: {
    offerId: {
      type: String
    }
  },
  data () {
    return {
      workDayCancelModal: 'workDayCancelModal',
      futureViewAlertModal: 'futureViewAlertModal',
      closedOfferModalText: 'Этот день был отменен',
      alertModalDescription: null,
      isEditMode: false,
      isLoading: false,
      selectedRowList: [],
      offer: null,
      cancelledLaborerNumber: 0,
      selectedUser: null,
      currentModal: '',
      modalName,
      keys: [
        { label: '', value: 'laborer', config: { canSort: false } },
        { label: 'Вакансия', value: 'vacancy.title', config: { canSort: false } },
        { label: 'Начала', value: 'startDate', config: { canSort: false } },
        { label: 'Конец', value: 'endDate', config: { canSort: false } },
        { label: 'Смена', value: 'duration', config: { canSort: false } },
        // { label: 'Доступные офферы', value: 'requiredLaborer', config: { canSort: false } },
        { label: 'Оплата', value: 'reservedAmount', config: { canSort: false } },
        { label: 'Статус оффера', value: 'offerStatus', config: { canSort: false } },
        { label: 'Отклики', value: 'respond', config: { canSort: false } },
        { label: 'Приглашения', value: 'invitation', config: { canSort: false } },
      ]
    }
  },

  computed: {
    futureOfferModalText () {
      return `Перейти на будущий оффер вы сможете после его публикации (за 48 часов до времени начала)`
    },
    getWorkDayList () {
      let list
      const self = this
      if (this.isEditMode) {
        list = this.offer.workDayList.filter((item) => item.status === 'created' && item.requiredLaborer > 0)
      } else {
        list = this.offer.workDayList
      }

      if (list.length === 1) {
        const curr = list[0]
        if (curr.offer) {
          self.$router.push({
            name: routeList.OFFICE_OFFER_ID_NAME,
            params: { id: this.getActiveOffice.id, offerId: curr.offer }
          })
        }
      }

      return list.sort((a, b) => {
        if (a.startAt > b.startAt) {
          return 1
        }
        if (a.startAt < b.startAt) {
          return -1
        }
        return 0
      })
    },
    selectedWorkDayIdList () {
      return this.selectedRowList.map((item) => item.id)
    },
    currentOffice () {
      return this.$store.state.office.userOffice
    },
    getMaxCancelableOffers () {
      if (!this.selectedRowList.length) {
        return 0
      }
      return Math.min(...this.selectedRowList.map((item) => parseInt(item.requiredLaborer)))
    },
    getSelectedOfferNumber () {
      return this.cancelledLaborerNumber * this.selectedRowList.length
    }
  },

  watch: {
  },
  destroyed () {
  },
  created () {
    this.reload()
  },
  beforeUpdate () {
  },
  updated () {
  },
  mounted () {
    this.$nextTick(function () {
    })
  },
  methods: {
    reload () {
      this.selectedRowList = []
      this.$store.dispatch('offer/fetchOfferSchedulerById', { id: this.$route.params.offerId })
        .then((response) => {
          this.offer =

          {
            ...mapOffer(response.data),
            workDayList: mapOfferList(response.data.workDayList)
          }
        })
    },
    comboChecker (item) {
      return !!(item.status === 'created' && this.findRow(item))
    },
    getLaborerColor (workDay) {
      if (workDay.status === 'active') {
        return '#8BD118'
      }

      if (workDay.status === 'created') {
        return '#FAC300'
      }

      return '#000000b3'
    },

    onCancel () {
    },
    openLaborerCancelModal () {
      this.currentModal = this.workDayCancelModal
      this.cancelledLaborerNumber = this.getMaxCancelableOffers
    },
    closeLaborerCancelModal () {
      this.currentModal = ''
      this.selectedRowList = []
    },
    onWorkDayCancel () {
      const payload = {
        offerWorkDayList: this.selectedRowList.map(({ id }) => ({ id })),
        quantity: this.cancelledLaborerNumber,
      }
      try {
        this.isLoading = true
        this.$store.dispatch('offer/cancelWorkDay', { payload })
          .then((response) => {
            this.toViewMode()
            this.closeLaborerCancelModal()
            this.isLoading = false
            this.reload()
          }).catch((error) => {
            console.log(error)
            this.isLoading = false
          })
      } catch (error) {
        console.log(error)
        this.isLoading = false
      }
    },
    onSelectAll (status) {
      if (!status) {
        this.selectedRowList = []
      } else {
        this.selectedRowList = this.getWorkDayList
      }
    },
    closeModal () {
      this.currentModal = ''
      this.alertModalDescription = null
    },
    toEditMode () {
      this.isEditMode = true
    },
    toViewMode () {
      this.isEditMode = false
    },
    findRow (item) {
      return this.selectedRowList.find((wd) => wd.id === item.id)
    },
    onRowSelected (item) {
      if (this.findRow(item)) {
        this.selectedRowList = this.selectedRowList.filter((el) => el.id !== item.id)
      } else {
        this.selectedRowList.push(item)
      }
      this.cancelledLaborerNumber = this.getMaxCancelableOffers
    },
    gotoView (workday) {
      if (!this.isEditMode) {
        if (workday.status === 'created') {
          this.alertModalDescription = this.futureOfferModalText
          this.currentModal = this.futureViewAlertModal
        } else if (workday.offer) {
          this.$router.push({
            name: routeList.OFFICE_OFFER_ID_NAME,
            params: { id: this.officeApi_officeUser.id, offerId: workday.offer }
          })
        } else {
          this.alertModalDescription = this.closedOfferModalText
          this.currentModal = this.futureViewAlertModal
        }
      }
    },
    onCancelNumberChange (increase) {
      const max = this.getMaxCancelableOffers

      if (this.cancelledLaborerNumber < max && increase) {
        ++this.cancelledLaborerNumber
      } else if (!increase) {
        if (this.cancelledLaborerNumber > 1) {
          --this.cancelledLaborerNumber
        }
      }
    },
    pluriaizeLaborer (offerNumber) {
      return pluriaizeLaborer(offerNumber)
    },
    pluriaizeOffers (offerNumber) {
      return pluriaizeOffers(offerNumber)
    },
  },
}
</script>

<style lang="sass" scoped>
.control-disabled
  opacity: 0.5
.control-wrapper
  cursor: pointer
.cancel-btn-txt
  color: rgba(237, 70, 47, 0.8)
.cancel-description
  text-align: center
  @include fontStyle(500, 12px)
  opacity: .2
  margin-top: 10px
.offer-item
  padding-bottom: 0 !important
  &__left
    display: flex
    flex-direction: column
    width: 45%
    margin-right: 30px
    &__header__status
      display: flex
      margin: 15px 0
      &:first-of-type
        margin-top: 0
    &__list-user
      overflow: auto
      flex-grow: 1
      align-items: flex-start
      &_empty
        @include fontStyle(500, 16px)
        margin: 40px auto 0 auto
        color: rgba(0, 0, 0, 0.51)
  &__right
    width: 55%
    background-color: white
    height: 100%

  $countColor: #A7A7A7
  $fontWeightBold: 800

  .vacancies__table
    font-family: $mainFont
    font-style: normal
    font-weight: 500
    font-size: 12px
    line-height: 15px
    &__employee
      padding-right: 10px
    &__vacancy
      font-weight: 600

    &__profession
      color: rgba(0, 0, 0, 0.60)

    &__pay
      font-weight: $fontWeightBold
      text-align: right


</style>
